<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">

        <div class="form-container">
          <div class="btn-group w-100" role="group" aria-label="Packets Nav" id="packetsNav" v-if="steps.packetNav">
            <input type="radio" class="btn-check" name="packets" id="package" autocomplete="off" checked>
            <label class="btn btn-outline-primary d-flex flex-column justify-content-end" for="package" @click="onPackage">
              <span class="d-block p-2">
                <svg v-if="selectedOptions.packageOption" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                </svg>
              </span>
              <p>Edullinen pakettitarjous</p>
            </label>

            <input type="radio" class="btn-check" name="packets" id="customize" autocomplete="off">
            <label class="btn btn-outline-primary d-flex flex-column justify-content-end" for="customize" @click="onCustomize">
              <span class="d-block p-2">
                <svg v-if="selectedOptions.customizeOption" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                </svg>
              </span>
              <p>Räätälöi oma pakettisi</p>
            </label>
          </div><!-- end packet nav-->
        </div>

        <!-- Start Package Content -->
        <transition name="fade">
          <div v-if="selectedOptions.packageOption" id="packageContent" class="form-container">
            <ul id="packetList" class="list-group mt-5">
              <li class="list-group-item d-flex justify-content-between align-items-center m-0"><h6>Määrä</h6></li>
              <li class="list-group-item d-flex justify-content-between align-items-center m-0" v-for="collection in collections" >
                {{collection.quantity}} kpl
                <span @click="selectQuantity('collection', collection.quantity)" :style="{ 'min-width': 100 + 'px' }"
                      class="badge pill text-white p-2"
                      style="background: #74C92C; font-size: 16px; cursor: pointer; font-family: Open Sans; font-weight: 400; "> {{collection.price}}€ pv </span>
              </li>
            </ul>
          </div>
        </transition>
        <!-- End Package Content -->

        <!-- Start Customize Content -->
        <transition name="fade">
          <div v-if="selectedOptions.customizeOption" id="customizeContent" class="">
            <div class="form-container">
              <div class="row">
                <h3 class="mt-5">Räätälöi oma pakettisi</h3>
                <p>Jos et halua valmista pakettia, voit räätälöidä itse laatikoiden määrän ja varauksen keston.</p>

                <div class="col-md-6 input-group mb-3">
                  <input :class="[errors.indexOf('boxes') != -1 ? 'error' : '']" type="text" class="form-control" v-model="formData.boxes" name="boxes" placeholder="Muuttolaatikoiden määrä" aria-label="Muuttolaatikoiden määrä" aria-describedby="boxes">
                  <button class="btn btn-outline-primary" type="button" id="boxes" @click="selectQuantity('custom', formData.boxes)">Varaa</button>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <!-- End Customize Content -->

        <!-- Start Delivery Step -->
        <transition name="fade">
          <div id="deliveryStep" v-if="steps.deliveryStep" class="">
            <div class="form-container">
              <div class="row">
                <h3>1. Nouto</h3>
                <p>Valitse, haetko muuttolaatikot itse vai toimitetaanko ne sinulle.</p>

                <div class="btn-group mt-4 mb-5" role="group" aria-label="">
                  <input type="radio" name="deliveryTransport" class="btn-check" id="deliveryTransport" autocomplete="off" checked>
                  <label class="btn btn-outline-primary btn-lg d-flex flex-column justify-content-end" for="deliveryTransport" @click="selectTransport(1, 'delivery')">
                  <span class="d-block">
                    <svg v-if="deliveryTransport" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                    </svg>
                  </span>
                    Toimituspalvelu (kuljetusmaksu)
                  </label>

                  <input type="radio" name="deliveryTransport" class="btn-check" id="noDeliveryTransport" autocomplete="off">
                  <label class="btn btn-outline-primary btn-lg d-flex flex-column justify-content-end" for="noDeliveryTransport" @click="selectTransport(0, 'delivery')">
                  <span class="d-block">
                    <svg v-if="!deliveryTransport" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                    </svg>
                  </span>
                    Noudan itse (ei lisämaksua)
                  </label>
                </div>
                <div class="col-md-12 mb-3 text-left">
                  <label for="start_d" class="form-label">Vuokrauksen alkamispäivä:</label>
                  <Datepicker
                      :class="[errors.indexOf('start_d') != -1 ? 'error' : '']"
                      v-model="formData.start_d"
                      placeholder="dd.mm.yyyy"
                      id="start_d"
                      format="dd.MM.yyyy"
                      autoApply
                      hideOffsetDates
                      closeOnScroll
                      :minDate="min_date"
                      :disabledWeekDays="[6, 0]"
                      :enableTimePicker="false"
                      :clearable="false"
                      :disabledDates="disabledDates"
                  />

                </div>
                <div class="col-md-12 mb-3 text-left" v-if="deliveryTransport">
                  <label class="form-label">Katuosoite ja talon numero mihin muuttolaatikoit toimitetaan: *</label>
                  <vue-google-autocomplete
                      id="location_start"
                      classname="form-control"
                      :class="[errors.indexOf('location_start') != -1 ? 'error' : '']"
                      placeholder="Esimerkikatu 1, Helsinki"
                      country="fi"
                      v-model="formData.location_start"
                      v-on:placechanged="getAddressData"
                  >
                  </vue-google-autocomplete>
                  <span v-bind:class="[errors.indexOf('isStartAddressSelected') != -1 ? 'd-block text-danger pt-1' : 'd-none']">Valitse osoite avattavasta luettelosta kirjoittaessasi</span>
                  <span class="d-block text-success p-2" style="font-size: 14px;"  v-if="formData.start_location">{{formData.start_location}}</span>
                </div>
                <div class="col-md-6 mb-3 text-left" v-if="deliveryTransport">
                  <label for="start_number" class="form-label">Rappu ja ovinumero: *</label>
                  <input :class="[errors.indexOf('start_number') != -1 ? 'error' : '']" v-model="formData.start_number" type="text" class="form-control" id="start_number" placeholder="x 130">
                </div>
                <div class="col-md-6 mb-3 text-left" v-if="deliveryTransport">
                  <label for="access_code" class="form-label">Lisätietoa (Esim. ovikoodi):</label>
                  <input :class="[errors.indexOf('access_code') != -1 ? 'error' : '']" v-model="formData.access_code" type="text" class="form-control" id="access_code" placeholder="ovikoodi">
                </div>
                <div class="col-md-12 mb-3 text-left" v-if="!deliveryTransport">
                  <label for="delivery_time" class="form-label">Alkamisaika::</label>
                  <select :class="[errors.indexOf('delivery_time') != -1 ? 'error' : '']" v-model="formData.delivery_time"  id="delivery_time" class="form-select" aria-label="Default select example">
                    <option class="option" selected>--valitse kellonaika--</option>
                    <option class="option" value="09-10">09:00 - 10:00</option>
                    <option class="option" value="10-11">10:00 - 11:00</option>
                    <option class="option" value="11-12">11:00 - 12:00</option>
                    <option class="option" value="12-13">12:00 - 13:00</option>
                    <option class="option" value="13-14">13:00 - 14:00</option>
                    <option class="option" value="14-15">14:00 - 15:00</option>
                    <option class="option" value="15-16">15:00 - 16:00</option>
                    <option class="option" value="17-18">17:00 - 18:00</option>
                  </select>
                </div>
                <div class="stepNav d-flex justify-content-between pt-5">
                  <a href="javascript:void(0)" class="link-secondary" @click="previousStep('deliveryStep')">Taaksenpäin</a>
                  <button type="button" class="btn btn-outline-primary" @click="goToPickupStep">Eteenpäin</button>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <!-- End Delivery Step -->

        <!-- Start Pickup Step -->
        <transition name="fade">
          <div id="pickupStep" v-if="steps.pickupStep">
            <div class="form-container">
              <div class="row">
                <h3>2. Palautus</h3>
                <p>Valitse, palautatko itse tai noudetaanko muuttolaatikot sinulta.</p>

                <div class="btn-group mt-4 mb-5" role="group" aria-label="">
                  <input type="radio" class="btn-check" name="pickupTransport" id="pickupTransport" autocomplete="off" checked>
                  <label class="btn btn-outline-primary btn-lg d-flex flex-column justify-content-end" for="pickupTransport" @click="selectTransport(1, 'pickup')">
                  <span class="d-block">
                    <svg v-if="pickupTransport" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                    </svg>
                  </span>
                    Toimituspalvelu (kuljetusmaksu)
                  </label>

                  <input type="radio" class="btn-check" name="pickupTransport" id="noPickupTransport" autocomplete="off">
                  <label class="btn btn-outline-primary btn-lg d-flex flex-column justify-content-end" for="noPickupTransport" @click="selectTransport(0, 'pickup')">
                  <span class="d-block">
                    <svg v-if="!pickupTransport" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                    </svg>
                  </span>
                    Noudan itse (ei lisämaksua)
                  </label>
                </div>
                <div class="col-md-12 mb-3 text-left">
                  <label for="end_d" class="form-label">Vuokrauksen alkamispäivä, päättymispäivä:</label>
                  <Datepicker
                      :class="[errors.indexOf('end_d') != -1 ? 'error' : '']"
                      v-model="formData.end_d"
                      placeholder="dd.mm.yyyy"
                      id="end_d"
                      format="dd.MM.yyyy"
                      autoApply
                      hideOffsetDates
                      closeOnScroll
                      :minDate="min_date"
                      :disabledWeekDays="[6, 0]"
                      :enableTimePicker="false"
                      :clearable="false"
                      :disabledDates="disabledDates"
                  />
                </div>
                <div class="col-md-12 mb-3 text-left" v-if="pickupTransport">
                  <label  class="form-label">Katuosoite ja talon numero mistä muuttolaatikot noudamme: *</label>
                  <vue-google-autocomplete
                      id="location_end"
                      classname="form-control"
                      :class="[errors.indexOf('location_end') != -1 ? 'error' : '']"
                      placeholder="Esimerkikatu 1, Helsinki"
                      country="fi"
                      v-model="formData.location_end"
                      v-on:placechanged="getAddressData"
                  >
                  </vue-google-autocomplete>
                  <span v-bind:class="[errors.indexOf('isEndAddressSelected') != -1 ? 'd-block text-danger pt-1' : 'd-none']">Valitse osoite avattavasta luettelosta kirjoittaessasi</span>
                  <span class="d-block text-success p-2" style="font-size: 14px;" v-if="formData.end_location">{{formData.end_location}}</span>
                </div>
                <div class="col-md-6 mb-3 text-left" v-if="pickupTransport">
                  <label for="end_number" class="form-label">Rappu ja ovinumero: *</label>
                  <input :class="[errors.indexOf('end_number') != -1 ? 'error' : '']" v-model="formData.end_number" type="text" class="form-control" id="end_number" placeholder="x 130">
                </div>
                <div class="col-md-6 mb-3 text-left" v-if="pickupTransport">
                  <label for="end_access_code" class="form-label">Lisätietoa (Esim. ovikoodi):</label>
                  <input :class="[errors.indexOf('end_access_code') != -1 ? 'error' : '']" v-model="formData.end_access_code" type="text" class="form-control" id="end_access_code" placeholder="ovikoodi">
                </div>
                <div class="col-md-12 mb-3 text-left" v-if="!pickupTransport">
                  <label for="pickup_time" class="form-label">Alkamisaika::</label>
                  <select :class="[errors.indexOf('pickup_time') != -1 ? 'error' : '']" v-model="formData.pickup_time" id="pickup_time" class="form-select" aria-label="Default select example">
                    <option value="" selected>--valitse kellonaika--</option>
                    <option class="option" value="09-10">09:00 - 10:00</option>
                    <option class="option" value="10-11">10:00 - 11:00</option>
                    <option class="option" value="11-12">11:00 - 12:00</option>
                    <option class="option" value="12-13">12:00 - 13:00</option>
                    <option class="option" value="13-14">13:00 - 14:00</option>
                    <option class="option" value="14-15">14:00 - 15:00</option>
                    <option class="option" value="15-16">15:00 - 16:00</option>
                    <option class="option" value="17-18">17:00 - 18:00</option>
                  </select>
                </div>
                <div class="stepNav d-flex justify-content-between pt-5">
                  <a href="javascript:void(0)" class="link-secondary" @click="previousStep('pickupStep')">Taaksenpäin</a>
                  <button type="button" class="btn btn-outline-primary" @click="goToOrderSummaryStep">Eteenpäin</button>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <!-- End Pickup Step -->

        <!-- Start Order Summary Step -->
        <transition name="fade">
          <div id="orderSummaryStep" v-if="steps.orderSummaryStep" class="form-container">
            <div class="row">
              <h3>Muuttolaatikko koko</h3>
              <ul id="orderDetails" class="list-group mt-3">
                <li class="list-group-item d-flex justify-content-between align-items-center m-0" v-if="formData.start_date">
                  <span>Toimitus: </span> <span class="lead">{{ formData.formatted_start_date }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center m-0" v-if="formData.end_date">
                  <span>Palautus: </span> <span class="lead">{{ formData.formatted_end_date }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center m-0">
                  <span v-if="formData.start_location" style="max-width: 200px">Katuosoite mihin muuttolaatikoit toimitetaan: </span> <span v-if="formData.start_location" class="lead">{{ formData.start_location }}</span>
                  <span v-if="formData.delivery_time" style="max-width: 200px">Alkamisaika: </span> <span class="lead">{{ formData.delivery_time }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center m-0" >
                  <span v-if="formData.end_location" style="max-width: 200px">Katuosoite mistä muuttolaatikot noudamme: </span> <span class="lead" v-if="formData.end_location">{{ formData.end_location }}</span>
                  <span v-if="formData.pickup_time" style="max-width: 200px">Päättymisaika: </span> <span class="lead">{{ formData.pickup_time }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center m-0" v-if="formData.boxes">
                  <span>Muuttolaatikoiden määrä: </span> <span class="lead">{{ formData.boxes }}</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center m-0" v-if="formData.boxes_price">
                  <span>Muuttolaatikot vuokraus: </span> <span class="lead">{{ formData.boxes_price }}&euro;</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center m-0" v-if="formData.delivery_price">
                  <span>Muuttolatikoiden tooimitus: </span> <span class="lead">{{ formData.delivery_price}}&euro;</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center m-0" v-if="formData.pickup_price">
                  <span>Muuttolaatikoiden nouto: </span> <span class="lead">{{formData.pickup_price}}&euro;</span>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center m-0" v-if="formData.price">
                  <span>Maksettava summa: </span> <span class="lead">{{ formData.price }}&euro;</span>
                </li>
              </ul>

              <div class="stepNav d-flex justify-content-between pt-5">
                <a href="javascript:void(0)" class="link-secondary" @click="previousStep('orderSummaryStep')">Taaksenpäin</a>
                <button type="button" class="btn btn-outline-primary" @click="goToOrderDetailsStep">Eteenpäin</button>
              </div>
            </div>
          </div>
        </transition>
        <!-- End Order Summary Step -->

        <!-- Start Order Details Step -->
        <transition name="fade">
          <div id="orderDetailsStep" v-if="steps.orderDetailsStep" class="form-container">
            <div class="form-container">
              <div class="row">
                <h3>Henkilötiedot</h3>

                <div class="col-md-6 mb-3 text-left">
                  <label for="firstName" class="form-label">Etunimi:</label>
                  <input :class="[errors.indexOf('firstName') != -1 ? 'error' : '']" v-model="formData.firstName" type="text" class="form-control" id="firstName" placeholder="Etunimi">
                </div>
                <div class="col-md-6 mb-3 text-left">
                  <label for="lastName" class="form-label">Sukunimi:</label>
                  <input :class="[errors.indexOf('lastName') != -1 ? 'error' : '']" v-model="formData.lastName" type="text" class="form-control" id="lastName" placeholder="Sukunimi">
                </div>

                <div class="col-md-6 mb-3 text-left">
                  <label for="phone" class="form-label">Puhelinnumero:</label>
                  <input :class="[errors.indexOf('phone') != -1 ? 'error' : '']" v-model="formData.phone" type="text" class="form-control" id="phone" placeholder="Puhelinnumero">
                </div>
                <div class="col-md-6 mb-3 text-left">
                  <label for="email" class="form-label">Email:</label>
                  <input :class="[errors.indexOf('email') != -1 ? 'error' : '']" v-model="formData.email" type="email" class="form-control" id="email" placeholder="Email">
                </div>
                <div class="col-md-12 mb-3 text-left">
                  <label for="payment_method" class="form-label">Valitse maksutapa:</label>
                  <select :class="[errors.indexOf('payment_method') != -1 ? 'error' : '']" v-model="formData.payment_method" id="payment_method" class="form-select" aria-label="Valitse maksutapa">
                    <option value="" selected>-- valitse maksutapa --</option>
                    <option class="option" v-for="method in paymentMethods" :value="method.selected_value">{{method.name}}</option>
                  </select>
                </div>

                <div class="stepNav d-flex justify-content-between pt-5">
                  <a href="javascript:void(0)" class="link-secondary" @click="previousStep('orderDetailsStep')">Taaksenpäin</a>
                  <button type="button" class="btn btn-outline-primary" @click="goToFinalStep">Eteenpäin</button>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <!-- End Order Details Step -->

      </div><!-- end row -->
    </div><!-- end row -->
  </div><!-- end container -->
  <div v-if="loading" class="loader-wrapper">
    <div class="loader"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import moment from 'moment';
import {openInNewTab} from "../components/Helper";

export default {
  name: 'Home',

  setup() {
    const store             = useStore();
    const errors            = ref([]);
    const loading           = ref(true);
    const selectedOptions   = ref({ packageOption: true, customizeOption: false});
    const steps             = ref({ packetNav: true, deliveryStep: false, pickupStep: false, orderSummaryStep: false, orderDetailsStep: false, finalStep: false});
    const deliveryTransport = ref(true);
    const pickupTransport   = ref(true);
    const stepType          = ref('');
    const formData          = ref({
      type: 'boxes',
      boxes: '',
      start_location: '',
      location_start: '',
      start_zip: '',
      start_number: '',
      access_code: '',
      dates: '',
      created_at:  new Date(),
      end_location: '',
      location_end: '',
      end_zip: '',
      end_number: '',
      end_access_code: '',
      start_d: '',
      end_d: '',
      start_date: '',
      end_date: '',
      formatted_start_date: '',
      formatted_end_date: '',
      price: '',
      delivery_price: '',
      pickup_price: '',
      boxes_price: '',
      transport_price: '',
      delivery_time: '',
      pickup_time: '',
      isStartAddressSelected: false,
      isEndAddressSelected: false,
      startDateSelected: false,
      endDateSelected: false,
      startCity: '',
      endCity: '',
      payment_method: '',
      deliveryTransport: 'yes',
      pickupTransport: 'yes',
      pickupTime: '',
      firstName: '',
      lastName:'',
      email: '',
      phone: '',
      returnUrl: 'https://www.muuttotarjous.fi/muuttolaatikot/?return-from-pay-page',
      origin: window.name ? window.name : 'muuttotarjous.fi',
    });

    const min_date = new Date();
    min_date.setDate(min_date.getDate() + 1);

    const onPackage = () => {
      loading.value = true;
      selectedOptions.value.customizeOption = false;
      selectedOptions.value.packageOption = true;

      setTimeout(()=>{
        loading.value = false;
      }, 500);
    };

    const onCustomize = () => {
      loading.value = true;
      selectedOptions.value.customizeOption = true;
      selectedOptions.value.packageOption = false;

      setTimeout(() => {
        loading.value = false;
      }, 500);
    };

    const getPriceDetails = async () => {
      loading.value = true;
      await store.dispatch("getPriceDetails");
      loading.value = false;
    };

    const selectQuantity = (type, quantity) => {
      errors.value = [];

      if (type === 'collection')
        formData.value.boxes = Number(quantity);

      if (type === 'custom'){
        if (!formData.value.boxes) {
          errors.value.push('boxes');
        }

        formData.value.boxes = Number(quantity);
      }

      if (!errors.value.length){
        loading.value = true;
        selectedOptions.value.packageOption = false;
        selectedOptions.value.customizeOption = false;

        steps.value.packetNav = false;
        steps.value.deliveryStep = true;

        setTimeout(()=>{
          loading.value = false;
        }, 500);
      }
    };

    const selectTransport = (selectedTransport, type) => {
      if (type === 'delivery')
        selectedTransport === 1 ? deliveryTransport.value = true : deliveryTransport.value = false;

      if (type === 'pickup')
        selectedTransport === 1 ? pickupTransport.value = true : pickupTransport.value = false;
    };

    const goToPickupStep = () => {
      errors.value = [];

      if (!formData.value.start_d) {
        errors.value.push('start_d');
      }

      if (deliveryTransport.value){
        if (!formData.value.start_number) {
          errors.value.push('start_number');
        }
        if (!formData.value.access_code) {
          errors.value.push('access_code');
        }
        if (!formData.value.location_start) {
          errors.value.push('location_start');
        }
        if (!formData.value.isStartAddressSelected) {
          errors.value.push('isStartAddressSelected');
        }
      }

      if (!deliveryTransport.value){
        if (!formData.value.delivery_time) {
          errors.value.push('delivery_time');
        }
      }

      if (!errors.value.length){
        loading.value = true;
        steps.value.deliveryStep = false;
        steps.value.pickupStep = true;

        if (deliveryTransport.value){
          formData.value.delivery_time = '';
        }

        if (!deliveryTransport.value){
          formData.value.start_location = '';
          formData.value.start_number = '';
          formData.value.access_code = '';
        }

        setTimeout(()=>{
          loading.value = false;
        }, 500);
      }
    };

    const getAddressData = (addressData, placeResultData, id) => {
      if (id === 'location_start'){
        formData.value.start_location = placeResultData.formatted_address
        formData.value.startCity = addressData.locality;
        formData.value.isStartAddressSelected = true;
      }

      if (id === 'location_end'){
        formData.value.end_location = placeResultData.formatted_address
        formData.value.endCity = addressData.locality;
        formData.value.isEndAddressSelected = true;
      }
    };

    const goToOrderSummaryStep = () => {
      errors.value = [];

      if (!formData.value.end_d) {
        errors.value.push('end_d');
      }

      if (pickupTransport.value){
        if (!formData.value.end_number) {
          errors.value.push('end_number');
        }
        if (!formData.value.end_access_code) {
          errors.value.push('end_access_code');
        }
        if (!formData.value.location_end) {
          errors.value.push('location_end');
        }
        if (!formData.value.isEndAddressSelected) {
          errors.value.push('isEndAddressSelected');
        }
      }

      if (!pickupTransport.value){
        if (!formData.value.pickup_time) {
          errors.value.push('pickup_time');
        }
      }

      if (!errors.value.length){
        loading.value = true;
        steps.value.pickupStep = false;
        steps.value.orderSummaryStep = true;

        formData.value.start_date = moment(formData.value.start_d).format('YYYY-MM-DD');
        formData.value.end_date   = moment(formData.value.end_d).format('YYYY-MM-DD');

        formData.value.formatted_start_date = moment(formData.value.start_d).format('DD.MM.YYYY');
        formData.value.formatted_end_date   = moment(formData.value.end_d).format('DD.MM.YYYY');

        if (pickupTransport.value){
          formData.value.pickup_time = '';
        }

        if (!pickupTransport.value){
          formData.value.end_location = '';
          formData.value.end_number = '';
          formData.value.end_access_code = '';
        }

        axios.post(
            process.env.VUE_APP_API_URL + '/wp-json/api/boxes/calculate',
            {
              data: formData.value,
            }
        ).then((res) => {

          console.log(res.data)
          formData.value.boxes_price          = res.data.boxes_price;
          formData.value.price                = res.data.price;
          formData.value.delivery_price       = res.data.delivery_transport;
          formData.value.pickup_price         = res.data.pickup_transport;
          loading.value = false;
        });
      }
    };

    const goToOrderDetailsStep = () => {
      loading.value = true;
      steps.value.orderSummaryStep = false;
      steps.value.orderDetailsStep = true;

      setTimeout(()=>{
        loading.value = false;
      }, 500);
    };

    const goToFinalStep = () => {
      event.preventDefault();
      errors.value = [];

      if (!formData.value.firstName) {
        errors.value.push('firstName');
      }
      if (!formData.value.lastName) {
        errors.value.push('lastName');
      }
      if (!formData.value.phone) {
        errors.value.push('phone');
      }
      if (!formData.value.email) {
        errors.value.push('email');
      }
      if (!formData.value.payment_method) {
        errors.value.push('payment_method');
      }

      if (!errors.value.length){
        loading.value = true;

        axios.post(
            process.env.VUE_APP_API_URL + '/wp-json/api/boxes/save-order',
            {
              data: formData.value,
            }
        ).then((res) => {
          loading.value = false;

          if (res.data.success_url){
            window.open(res.data.success_url, "_blank").focus();
            // openInNewTab(res.data.success_url)
            window.location.reload();
            sessionStorage.setItem('storedData', JSON.stringify(formData.value));
          }
        });
      }
    };

    const previousStep = (step) => {
      if (step === 'deliveryStep'){
        window.location.reload();
      }

      if (step === 'pickupStep'){
        loading.value = true;
        steps.value.pickupStep = false;
        steps.value.deliveryStep = true;

        setTimeout(() => {
          loading.value = false;
        }, 500);
      }

      if (step === 'orderSummaryStep'){
        loading.value = true;
        steps.value.orderSummaryStep = false;
        steps.value.pickupStep = true;

        setTimeout(() => {
          loading.value = false;
        }, 500);
      }

      if (step === 'orderDetailsStep'){
        loading.value = true;
        steps.value.orderDetailsStep = false;
        steps.value.orderSummaryStep = true;

        setTimeout(()=>{
          loading.value = false;
        }, 500);
      }

    };

    const collections = store.getters.getCollections;

    const paymentMethods = computed(() => store.getters.getPaymentMethods);

    const disabledDates = computed(() => {
      const dates = [];

      store.getters.getBlockedDates.map((item) => {
        item.block ? dates.push(new Date(item.date)) : '';
      });

      return dates
    });

    const blockedDates = computed(() => store.getters.getBlockedDates)

    onMounted(() => {
      sessionStorage.getItem('storedData') ? formData.value = JSON.parse(sessionStorage.getItem('storedData')) : '';
      formData.value.start_location ? formData.value.location_start = formData.value.start_location : '';

      getPriceDetails();
      store.dispatch("getPaymentMethods");
      store.dispatch("getBlockedDates");

      const EXPIRE_TIME = 5000 * 60;
      setTimeout(function() {
        sessionStorage.removeItem('storedData');
      }, EXPIRE_TIME);

    });

    return {
      onPackage,
      onCustomize,
      getPriceDetails,
      selectQuantity,
      selectTransport,
      goToPickupStep,
      getAddressData,
      goToOrderSummaryStep,
      goToOrderDetailsStep,
      goToFinalStep,
      previousStep,
      min_date,
      errors,
      loading,
      selectedOptions,
      steps,
      deliveryTransport,
      pickupTransport,
      stepType,
      formData,
      collections,
      paymentMethods,
      blockedDates,
      disabledDates,
    }
  },

  components: {VueGoogleAutocomplete}
}
</script>


